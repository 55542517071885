import PropTypes from 'prop-types';
import { useMemo, useState, useEffect, useReducer, useCallback } from 'react';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';

import { firebaseApp } from './lib';
import { AuthContext } from './auth-context';
import { getUser, createUser, updateUser } from '../../redux/slices/user';



const AUTH = getAuth(firebaseApp);

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  return state;
};

export function AuthProvider ({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);

  const syncUserWithDatabase = useCallback(async (firebaseUser, role) => {

    try {

      let userProfile = await getUser();

      if (!userProfile) {

        if (!role) {
          return // If no role is provided, do not create user
        }

        // Create new user in database if not exists  
        userProfile = await createUser({
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          displayName: firebaseUser.displayName,
          photoURL: firebaseUser.photoURL,
          emailVerified: firebaseUser.emailVerified,
          lastLoginAt: new Date().toISOString(),
          role,
        });
      } else {
        // Update existing user
        const updateData = {
          emailVerified: firebaseUser.emailVerified,
          photoURL: firebaseUser.photoURL,
          lastLoginAt: new Date().toISOString(),
        };

        userProfile = await updateUser(updateData);
      }

      // eslint-disable-next-line consistent-return
      return {
        ...firebaseUser,
        ...userProfile,
      };
    } catch (error) {
      console.error('Error syncing user with database:', error);
      // eslint-disable-next-line consistent-return
      return null;
    }
  }, []);

  const initialize = useCallback(() => {

    onAuthStateChanged(AUTH, async (user) => {

      if (user) {
        if (isLoginInProgress) {
          return;
        }
        if (user.emailVerified) {
          const syncedUser = await syncUserWithDatabase(user);

          dispatch({
            type: 'INITIAL',
            payload: { user: syncedUser },
          });
        }
        else {
          dispatch({
            type: 'INITIAL',
            payload: { user: null },
          });
        }
      } else {
        dispatch({
          type: 'INITIAL',
          payload: { user: null },
        });
      }
    });
  }, [isLoginInProgress, syncUserWithDatabase]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const login = useCallback(async (email, password) => {
    const userCredential = await signInWithEmailAndPassword(AUTH, email, password).catch(err => {
      if (err.code === 'auth/user-not-found') {
        throw new Error('User not found');
      }
      if (err.code === 'auth/invalid-credential') {
        throw new Error('Invalid email or password');
      }
      throw new Error(err.message || 'An error occurred. Please try again.');
    });

    await syncUserWithDatabase(userCredential.user);
  }, [syncUserWithDatabase]);

  const loginWithGoogle = useCallback(async (roleFromRegister = null) => {
    setIsLoginInProgress(true);

    const provider = new GoogleAuthProvider();
    const userCredential = await signInWithPopup(AUTH, provider);

    await syncUserWithDatabase(userCredential.user, roleFromRegister);
    setIsLoginInProgress(false);


  }, [syncUserWithDatabase]);

  const register = useCallback(async (email, password, firstName, lastName, role = "user") => {
    setIsLoginInProgress(true);

    const userCredential = await createUserWithEmailAndPassword(AUTH, email, password).catch(err => {

      if (err.code === 'auth/email-already-in-use') {
        throw new Error('Email is already in use');
      } else {
        throw new Error(err.message || 'An error occurred. Please try again.');
      }
    });

    await sendEmailVerification(userCredential.user);

    await createUser({
      uid: userCredential.user.uid,
      email,
      role,
      displayName: `${firstName} ${lastName}`,
      emailVerified: false,
      lastLoginAt: new Date().toISOString(),
    });
    setIsLoginInProgress(false);
  }, []);

  const logout = useCallback(async () => {
    await signOut(AUTH);
  }, []);

  const forgotPassword = useCallback(async (email) => {
    await sendPasswordResetEmail(AUTH, email);
  }, []);

  const checkAuthenticated = state.user?.emailVerified ? 'authenticated' : 'unauthenticated';
  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'firebase',
      loading: status === 'loading',
      isAuthenticated: status === 'authenticated',
      isUnauthenticated: status === 'unauthenticated',
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
    }),
    [
      status,
      state.user,
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};